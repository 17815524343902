var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import $ from "jquery";
import { PainterWrapper } from "./component/PainterWrapper/PainterWrapper";
import { observer } from "mobx-react";
import { LabelHeader } from "./component/LabelHeader/LabelHeader";
import { LabelSelector } from "./component/LabelSelector/LabelSelector";
import { LabelDisplay } from "./component/LabelDisplay/LabelDisplay";
import { LabelFooterStatus } from "./component/LabelFooterStatus/LabelFooterStatus";
import { parseLocationSearch } from "../../../../util/parse-location-search";
import { imageLabelStore } from "./ImageLabelStore";
import { keymapStore } from "./provider/keymap-store";
import { KeyboardEventKey, PainterMode } from "../interface/common";
import "./ImageLabel.less";
import { IResource } from "definition/entity/resource";
import videoLabelStore from "../../video/VideoLabel/VideoLabelStore";
import SplitView from "../../../../component/SplitView";
import { Orientation } from "component/Sash";
import { PainterDom } from "./provider/painter-dom";
import SegmentDisplay from "./component/SegmentDisplay/SegmentDisplay";
import { autorun, toJS } from "mobx";
import { isArrowKey } from "./component/DetectionElement/painter/rect-painter";
import { message } from "antd";
import { ILabelTask } from "definition/entity/label-task";
import { boxPainter } from "./component/DetectionElement/painter/box-painter";
let ImageLabel = class ImageLabel extends React.Component {
    constructor() {
        super(...arguments);
        this.workspaceRef = null;
        this.state = {
            size: window.innerWidth
        };
        this.onWindowResize = () => {
            this.setState({ size: window.innerWidth });
        };
        this.workspaceMouseWheel = (event) => {
            if (event.ctrlKey) {
                const { zoomRatio: originZoomRatio } = imageLabelStore.status;
                const deltaY = _.get(event, "wheelDelta", event.deltaY);
                let nextZoomRatio = +Math.min(originZoomRatio + deltaY / 2400, 20).toFixed(2);
                if (nextZoomRatio < 0.1) {
                    nextZoomRatio = 0.1;
                }
                if (imageLabelStore.status.commonMode) {
                    message.warn("共边模式下不允许缩放视图");
                }
                else {
                    imageLabelStore.updateZoomRatio(nextZoomRatio, event);
                }
                event.preventDefault();
            }
        };
        this.imageLabelOnKeyDown = async (event) => {
            const status = imageLabelStore.status;
            if (this.isWriteableElement(event)) {
                return;
            }
            if (status.readonly) {
                await this.readonlyOnKeyDown(event);
            }
            else {
                await this.normalOnKeyDown(event);
            }
        };
        this.normalOnKeyDown = async (event) => {
            var _a, _b, _c;
            keymapStore.trigger(event);
            const status = imageLabelStore.status;
            const { metaKey } = event;
            if (status.mime === IResource.Mime.Image || status.mime === IResource.Mime.Cloud) {
                const hasSegment = !!((_a = imageLabelStore.status.selectableLabel.segments) === null || _a === void 0 ? void 0 : _a.length);
                const e = event;
                const polygonRender = imageLabelStore.painterWrapperRef.polygonRender;
                if (hasSegment) {
                    if (e.key === "G" && e.shiftKey) {
                        if (imageLabelStore.selectedElements.length >= 2) {
                            if (new Set(imageLabelStore.selectedElements.map(v => v.label.key)).size !== 1) {
                                message.warn("只有相同标签的元素才可以组合！");
                            }
                            else if (new Set(imageLabelStore.selectedElements.map(v => v.detection.layer)).size !== 1) {
                                message.warn("请先确保被关联标注对象的所在图层一致！");
                            }
                            else {
                                const hasSameFeatureId = imageLabelStore.selectedElements.every(el => el.detection.featureId == imageLabelStore.selectedElements[0].detection.featureId);
                                if (hasSameFeatureId) {
                                    imageLabelStore.painterWrapperRef.setState({ deleteGroupDialogVisible: true });
                                }
                                else {
                                    imageLabelStore.painterWrapperRef.setState({ createGroupDialogVisible: true });
                                }
                            }
                        }
                    }
                    if (e.key.toLowerCase() === "z") {
                        if (e.ctrlKey || e.metaKey) {
                            if (e.shiftKey) {
                                imageLabelStore.history.redo();
                            }
                            else {
                                imageLabelStore.history.undo();
                            }
                        }
                    }
                    if (e.key.toLowerCase() === "q") {
                        imageLabelStore.status.commonMode = polygonRender.commonMode = !polygonRender.commonMode;
                        if (polygonRender.commonMode) {
                            if (imageLabelStore.status.creatingElement) {
                                const targetElement = imageLabelStore.status.creatingElement;
                                polygonRender.targetElement = targetElement;
                                polygonRender.targetElementPointIndex = targetElement.attributes.points.length - 1;
                            }
                            else {
                            }
                        }
                        else {
                            polygonRender.targetElement = null;
                            polygonRender.targetElementPointIndex = null;
                            polygonRender.relativeElement = null;
                            polygonRender.relativeElementPointIndexes = [];
                        }
                    }
                    if (polygonRender.commonMode && e.key === "v") {
                        polygonRender.reverse = !polygonRender.reverse;
                        polygonRender.doCommonMode();
                        imageLabelStore.painterWrapperRef.rerender();
                    }
                    if (isArrowKey(e.key)) {
                        if (imageLabelStore.selectedElements.length) {
                            if (imageLabelStore.status.selectedPointIndex.length) {
                                if (e.key === "ArrowLeft") {
                                    polygonRender.safeMovePoint(-1, 0, false);
                                }
                                else if (e.key === "ArrowRight") {
                                    polygonRender.safeMovePoint(1, 0, false);
                                }
                                else if (e.key === "ArrowUp") {
                                    polygonRender.safeMovePoint(0, -1, false);
                                }
                                else if (e.key === "ArrowDown") {
                                    polygonRender.safeMovePoint(0, 1, false);
                                }
                            }
                            else {
                                const elements = imageLabelStore.selectedElements;
                                elements.forEach(element => {
                                    if (e.key === "ArrowLeft") {
                                        polygonRender.safeMove(element, -1, 0);
                                    }
                                    else if (e.key === "ArrowRight") {
                                        polygonRender.safeMove(element, 1, 0);
                                    }
                                    else if (e.key === "ArrowUp") {
                                        polygonRender.safeMove(element, 0, -1);
                                    }
                                    else if (e.key === "ArrowDown") {
                                        polygonRender.safeMove(element, 0, 1);
                                    }
                                });
                            }
                            imageLabelStore.painterWrapperRef.rerender();
                        }
                        else {
                            if (e.key === KeyboardEventKey.ArrowLeft) {
                                e.preventDefault();
                                await imageLabelStore.prevResource();
                                return;
                            }
                            if (e.key === KeyboardEventKey.ArrowRight) {
                                e.preventDefault();
                                await imageLabelStore.nextResource();
                                return;
                            }
                        }
                    }
                }
                else {
                    if (event.key === KeyboardEventKey.ArrowLeft) {
                        event.preventDefault();
                        await imageLabelStore.prevResource();
                        return;
                    }
                    if (event.key === KeyboardEventKey.ArrowRight) {
                        event.preventDefault();
                        await imageLabelStore.nextResource();
                        return;
                    }
                }
            }
            else if (status.mime === IResource.Mime.Video) {
                let step = 1;
                if (event.shiftKey) {
                    step = 10;
                }
                if (event.key === KeyboardEventKey.ArrowLeft) {
                    event.preventDefault();
                    videoLabelStore.back(step);
                    return;
                }
                if (event.key === KeyboardEventKey.ArrowRight) {
                    event.preventDefault();
                    videoLabelStore.forward(step);
                    return;
                }
            }
            if (event.key === KeyboardEventKey.Backspace || event.key === KeyboardEventKey.Delete) {
                if (event.shiftKey) {
                    const selectedSegmentElement = imageLabelStore.status.editingElement;
                    if (selectedSegmentElement &&
                        (selectedSegmentElement.label.type === "mask-instance" ||
                            selectedSegmentElement.label.type === "mask-semantic")) {
                        if (((_c = (_b = selectedSegmentElement === null || selectedSegmentElement === void 0 ? void 0 : selectedSegmentElement.attributes) === null || _b === void 0 ? void 0 : _b.points) === null || _c === void 0 ? void 0 : _c.length) - status.selectedPointIndex.length >= 3) {
                            imageLabelStore.history.push({
                                path: `status.layerElements[${_.findIndex(imageLabelStore.status.layerElements, {
                                    id: selectedSegmentElement.id
                                })}]`,
                                getValue: (oz, cz, ov) => {
                                    const v = ov || toJS(selectedSegmentElement);
                                    v.attributes.points.forEach(p => {
                                        p[0] = (p[0] * cz) / oz;
                                        p[1] = (p[1] * cz) / oz;
                                    });
                                    return v;
                                }
                            });
                            _.pullAt(selectedSegmentElement.attributes.points, ...status.selectedPointIndex);
                            status.selectedPointIndex = [];
                            status.selectedSegmentElementId = null;
                            imageLabelStore.painterWrapperRef.polygonRender.fixRect(selectedSegmentElement);
                        }
                        else {
                            return message.error("删除后无法形成闭合区域，禁止删除！");
                        }
                    }
                }
                else {
                    if (imageLabelStore.status.editingElement &&
                        (imageLabelStore.status.editingElement.label.type === "mask-instance" ||
                            imageLabelStore.status.editingElement.label.type === "mask-semantic")) {
                        message.error("选点模式下不允许删除对象！");
                    }
                    else {
                        imageLabelStore.history.push({
                            path: "status.layerElements",
                            getValue: (oz, cz, ov) => {
                                const els = ov || toJS(status.layerElements);
                                els.forEach(el => {
                                    var _a;
                                    (_a = el.attributes.points) === null || _a === void 0 ? void 0 : _a.forEach(p => {
                                        p[0] = (p[0] * cz) / oz;
                                        p[1] = (p[1] * cz) / oz;
                                    });
                                });
                                return els;
                            },
                            group: imageLabelStore.group.get()
                        });
                        for (const id of status.selectedElementIds) {
                            imageLabelStore.group.deleteGroup(id);
                        }
                        _.remove(status.layerElements, el => status.selectedElementIds.includes(el.id));
                        status.selectedElementIds = [];
                        message.info("该对象已删除");
                    }
                }
                imageLabelStore.painterWrapperRef.rerender();
            }
            if (event.key === KeyboardEventKey.Escape) {
                status.mode = PainterMode.UNSET;
                status.curSelectedLabel = null;
                status.creatingElement = null;
                status.selectedElementIds = [];
                status.spriteContextmenu = {
                    visible: false
                };
                imageLabelStore.painterWrapperRef.rerender();
                imageLabelStore.emit("unselectLabel");
                status.editingElementId = null;
                boxPainter.reset();
                return;
            }
            if (event.key === "a" && metaKey) {
                status.selectedElementIds = status.layerElements.map(i => i.id);
                event.preventDefault();
                imageLabelStore.painterWrapperRef.rerender();
                return;
            }
            if (event.key === "h" || event.key === "H") {
                status.layerElements
                    .filter(item => !status.selectedElementIds.includes(item.id))
                    .forEach(e => (e.visible = !e.visible));
                imageLabelStore.painterWrapperRef.rerender();
                return;
            }
            if (event.key.toLowerCase() === "j") {
                imageLabelStore.selectPrev();
            }
            if (event.key.toLowerCase() === "k") {
                imageLabelStore.selectNext();
            }
        };
        this.readonlyOnKeyDown = async (event) => {
            if (imageLabelStore.status.mime === IResource.Mime.Image) {
                if (event.key === KeyboardEventKey.ArrowLeft) {
                    event.preventDefault();
                    await imageLabelStore.prevResource();
                    return;
                }
                if (event.key === KeyboardEventKey.ArrowRight) {
                    event.preventDefault();
                    await imageLabelStore.nextResource();
                    return;
                }
            }
        };
    }
    async componentDidMount() {
        const { labelTaskId, labelPackageId } = this.props.match.params;
        const { readonly, index } = parseLocationSearch(this.props.location.search);
        if (readonly) {
            imageLabelStore.status.readonly = true;
        }
        if (index) {
            imageLabelStore.status.curOffset = index - 1;
        }
        await imageLabelStore.initLabelPage(+labelTaskId, +labelPackageId);
        await imageLabelStore.loadLabelProgress();
        if (imageLabelStore.status.mime === IResource.Mime.Video) {
            imageLabelStore.syncVideoInfo();
        }
        this.workspaceRef.addEventListener("mousewheel", this.workspaceMouseWheel);
        document.body.addEventListener("keydown", this.imageLabelOnKeyDown);
        window.addEventListener("resize", this.onWindowResize);
        autorun(() => {
            var _a, _b;
            imageLabelStore.status.enableContextMenu =
                ((_b = (_a = imageLabelStore.status.labelInfo) === null || _a === void 0 ? void 0 : _a.labelTask) === null || _b === void 0 ? void 0 : _b.taskType) == 1 ? false : true;
        });
    }
    componentWillUnmount() {
        imageLabelStore.resetStatus();
        imageLabelStore.isProcessing = false;
        keymapStore.releaseAll();
        this.workspaceRef.removeEventListener("mousewheel", this.workspaceMouseWheel);
        document.body.removeEventListener("keydown", this.imageLabelOnKeyDown);
        window.removeEventListener("resize", this.onWindowResize);
    }
    isWriteableElement(e) {
        if (e.target.tagName === "INPUT" && e.target.type === "text") {
            return true;
        }
        return false;
    }
    get workspaceWidth() {
        return window.innerWidth - 240 - 300;
    }
    render() {
        var _a, _b, _c;
        const status = imageLabelStore.status;
        return (<div tabIndex={-1} id="app-layout-component" className="app-layout-component" onContextMenu={event => event.preventDefault()}>
        <div className="app-header">
          <LabelHeader />
        </div>
        <div className="app-content">
          <SplitView size={this.state.size} orientation={Orientation.VERTICAL} _views={[{ initialSize: 240 }, { initialSize: this.state.size - 240 - 350 }, { initialSize: 350 }]} onChange={() => {
            PainterDom.adjustPainterSize();
            PainterDom.adjustScroll();
        }}>
            <div className="app-content-left">
              <LabelSelector />
            </div>
            <div id="workspace" className="app-content-center" style={{ backgroundColor: status.workspaceTheme ? "#000000" : "#ffffff" }} ref={ref => {
            this.workspaceRef = ref;
            imageLabelStore.workspace = $(ref);
        }}>
              <div className="filename-view">
                <span>{status.curFilename}</span>
              </div>
              <PainterWrapper key={(_a = imageLabelStore.status.curResource) === null || _a === void 0 ? void 0 : _a.url} ref={el => {
            imageLabelStore.painterWrapperRef = el;
        }}/>
            </div>
            <div className="app-content-right">
              {((_c = (_b = status.labelInfo) === null || _b === void 0 ? void 0 : _b.labelTask) === null || _c === void 0 ? void 0 : _c.labelTaskType) == ILabelTask.LabelTaskType.Clipping ? (<SegmentDisplay />) : (<LabelDisplay ref={el => (imageLabelStore.labelDisplay = el)}/>)}
            </div>
          </SplitView>
          
          {status.readonly && (<div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#8FB0D1",
            opacity: 0.3,
            zIndex: 10
        }}></div>)}
        </div>
        <div className="app-footer">
          <LabelFooterStatus />
        </div>
      </div>);
    }
};
ImageLabel = __decorate([
    observer
], ImageLabel);
export { ImageLabel };
